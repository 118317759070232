<template>
  <div class="stu_jd_score">
    <h3>{{ stuName }}</h3>
    <div class="tj">
      <Fenxi :fenxi="tj"></Fenxi>
    </div>
    <div class="scores">
      <div v-for="(item, index) in scores" :key="index">
        <StuScore :title="item.keChengName" :des="item.score" :warn="true">
          <template>
            <ul class="kaoshi_info">
              <li>班级:{{ item.className }}</li>
              <li>学生:{{ item.studentName }}</li>
              <li>课程:{{ item.keChengName }}</li>
              <li>阶段:{{ item.jieDuanName }}</li>
              <li>时间:{{ item.examTime }}</li>
            </ul>
          </template>
        </StuScore>
      </div>
    </div>
  </div>
</template>
<script>
import StuScore from "@/components/StuScore.vue";
import Fenxi from "@/components/fenxi.vue";
import { mapState } from "vuex";
export default {
  name: "JDScore",
  data() {
    return {
      scores: [],
      stuName: "张三",
    };
  },
  computed: {
    ...mapState(["loading"]),
    tj() {
      let total = this.scores.length;
      let youxiu = 0,
        jige = 0,
        bujige = 0;
      for (let i in this.tableData) {
        if (this.tableData[i].score <= 60) {
          bujige++;
        } else if (this.tableData[i].score >= 90) {
          youxiu++;
        } else {
          jige++;
        }
      }
      return { totle: total, jige, bujige, youxiu };
    },
  },
  async created() {
    this.$datas.meta = { xuehao: this.$route.params.jd };
    this.stuName = this.$route.query.stuName;
    let res = await this.$datas.jdScore;
    let className = this.$route.params.jd.split('-')[0];
    let jieduan = this.$route.query.jieduan;
    let datas = res.data.msg.data;
    let temps = [];
    for (let i in datas) {
      let item = datas[i];
      if (item.jieDuanName == jieduan && item.className == className) {
        temps.push(item);
      }
    }
    this.scores = temps;
  },
  components: {
    StuScore,
    Fenxi,
  },
  methods: {},
};
</script>
<style lang="less">
.stu_jd_score {
  padding: 10px;
  .tj {
    display: flex;
    justify-content: space-between;
    color: #606266;
    padding: 10px 0;
  }
  .scores {
    &:after {
      content: ".";
      display: block;
      clear: both;
      font-size: 0;
    }
    > div {
      cursor: pointer;
    }
  }
  .kaoshi_info {
    list-style: none;
    li {
      margin: 5px 0;
    }
  }
}
</style>
